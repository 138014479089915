import React, { useEffect, useState } from "react";
import avatar from "../../assets/images/user.png"
import { Info } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import { GetStudentDetails } from "../../api/student";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import NewsFeed from "../../components/NewsFeed";
import SmallCard from "../../components/SmallCard";
import usePayments from "../../hooks/usePayments";
import CourseRegCard from "../../components/CourseRegCard";
import HostelSmallCard from "../../components/HostelSmallCard";
import api from "../../api/api";

function Dashboard() {
    const user = getCurrentUser()
  const [passport, setPassport] = useState();
  const [details, setDetails] = useState([]);
  const [courseDetails, setCourseDetails] = useState()
  
  const {data:dashboard} = useDetails()
  const {payment} = usePayments()
  const schoolFee = payment?.school_fees_payment
  const hostelFee = payment?.hostel_fees_payment

  const location = useLocation();
  const navigate = useNavigate();
  const [courses, setCourses] = useState()

  const firstSemesterCourses = courses?.filter(course => (course.semester).toLowerCase() === 'first')
  const secondSemesterCourses = courses?.filter(course => (course.semester).toLowerCase() === 'second')

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(`/courses/student-view`);
      setCourses(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const getDetails = async () => {
    try {
      const data = await GetStudentDetails(user);
      console.log(data, "dataaaa");
      setPassport(data.photo);
      // setStage(data.stage);
      setDetails(data);
      setCourseDetails(data.admin_response[0].approved_program)
    } catch (error) {}
  };



  useEffect(() => {
    getDetails();
    getRegisteredCourses()
  }, []);

  return (
    <div class="page-wrapper dspg-warning">
      {(dashboard && details) && 
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
                <h4 class="page-title">Dashboard</h4>
              </div>
            </div>
          </div>
          <div class="alert dspg-success" role="alert">
            Welcome, <strong>{details?.othername}</strong>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="met-profile">
                    <div class="row">
                      <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                        <div class="met-profile-main">
                          <div class="met-profile-main-pic">
                            <img
                              src={passport?passport:avatar}
                              onError={onImageError}
                              alt="Passport"
                              className="rounded-circle"
                              style={{ height: "100px", objectFit: "contain" }}
                            />
                          </div>
                          <div class="met-profile_user-detail">
                            <h3 class="">
                              {details
                                ? details?.surname + " " + details?.othername
                                : ""}
                            </h3>
                            <p class="mb-0">Student</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 ml-auto">
                        <ul class="list-unstyled">
                          <li class="">
                            <i class="dripicons-phone mr-2 text-info font-18"></i>{" "}
                            <b> phone </b> : {details ? details?.phone : ""}
                          </li>
                          <li class="mt-2">
                            <i class="dripicons-mail text-info font-18 mt-2 mr-2"></i>{" "}
                            <b> Email </b> : {details ? details?.email : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <SmallCard title="School Fee" paymentStatus={schoolFee} invoice={schoolFee}/> 
                </div>                          
                <div class="col-md-6 col-lg-6">
                    <CourseRegCard
                      title="Course Registration"
                      firstSemester={firstSemesterCourses}
                      secondSemester={secondSemesterCourses}
                    />
                </div>                           
            </div>
          <div className="row">
            <div class="col-lg-8 align-self-center">
              <div className="card">
                <div className="card-body">
                  <div class="table-responsive">
                    <h5 class="mt-0 mb-3 mt-1">
                      <b>Candidate Details</b>
                    </h5>
                    <hr />
                  <table className="table mb-0 table-centered">
                    <tbody>
                        <tr>
                          <td>Matric Number</td>
                          <td>{details?.matric_no}</td>
                        </tr>
                        <tr>
                          <td>Application Number</td>
                          <td>{details?.application_no}</td>
                        </tr>
                        <tr>
                          <td>Course of Study</td>
                          <td>{details?.department}</td>
                        </tr>
                        <tr>
                          <td>Session of Admission</td>
                          <td>{details?.entry_session}</td>
                        </tr>
                        <tr>
                          <td>faculty</td>
                          <td>{details?.faculty}</td>
                        </tr>
                        <tr>
                          <td>Programme</td>
                          <td>{details?.programme}</td>
                        </tr>
                        <tr>
                          <td>Entry Mode</td>
                          <td>{details?.entry_mode}</td>
                        </tr>
                        <tr>
                          <td>Study Mode</td>
                          <td>{details?.study_mode}</td>
                        </tr>
                    </tbody>
                  </table>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div className="card dspg-warning">
                <div className="card-body">
                  <div class="table-responsive">
                    <h5 class="mt-0 mb-3 mt-1">
                      <b>Newsfeed</b>
                    </h5>
                    <hr />
                    <NewsFeed/>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      } 
    </div>
  );
}

export default Dashboard;
